import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="automations--content-block-form"
export default class extends Controller {
  static targets = ["form", "typeSelect", "plannedFields", "fixedTimeFields"];

  connect() {
    this.validateInputs();
  }

  validateInputs() {
    if (!this.formTarget) {
      throw new Error(
        `form target for data-controller="automations--content-block-form" must be set`
      );
    }

    if (!this.typeSelectTarget) {
      throw new Error(
        `typeSelect target for data-controller="automations--content-block-form" must be set`
      );
    }

    if (!this.plannedFieldsTarget) {
      throw new Error(
        `plannedFields target for data-controller="automations--content-block-form" must be set`
      );
    }

    if (!this.fixedTimeFieldsTarget) {
      throw new Error(
        `fixedTimeFields target for data-controller="automations--content-block-form" must be set`
      );
    }
  }

  toggleType() {
    const isRange = this.typeSelectTarget.value === "planned";

    this.plannedFieldsTarget.classList.toggle("hidden", !isRange);
    this.fixedTimeFieldsTarget.classList.toggle("hidden", isRange);
  }

  resetUnselected() {
    const isRange = this.typeSelectTarget.value === "planned";

    if (isRange) {
      this.clearFixedTimeInput();
    } else {
      this.clearDateRange();
    }
  }

  clearDateRange() {
    this.plannedFieldsTarget.querySelector(
      'input[name*="schedule_starts_at"]'
    ).value = "";
    this.plannedFieldsTarget.querySelector(
      'input[name*="schedule_ends_at"]'
    ).value = "";
  }

  clearFixedTimeInput() {
    this.fixedTimeFieldsTarget.querySelector(
      'input[name*="fixed_send_time"]'
    ).value = "";
  }
}
